import { useEffect, useState } from "react"

// cmp
import StepTemplate from "../../../Components/StepTemplate/StepTemplate"
import Swal from 'sweetalert2';


import logo from "../../../img/logo.png"
import Input from "../../../Components/Input/Input"

export default function({stepCounter, setAbleNextStep, setStepCounter, brokerData, setBrokerData}) {
  // variables del formulario
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [keyword, setKeyword] = useState("")
  const LLHAHKAFSDPHOPQÑLASDLJK = "Banqueros Clave"


  // conexión a base de datos de asesores
  const SteinStore = require("stein-js-client");
  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/64b6edfbeced9b09e9e1d5ff"
  );




  
  useEffect(() => {
    if(username && password && keyword) {
      setAbleNextStep(true)
    } else {
      setAbleNextStep(false)
    }





    
    if(stepCounter == 1) {
      setStepCounter(0)

      const loadingSwal = Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
            





      
      store
        .read("Asesores", {authentication: {
          username: "asesor",
          password: "clave12345678909876543211123456789999999987654"
        }})
        .then(data => {
          let asesoresDB = data;
          let matchIdx = null



          asesoresDB.forEach((asesor, idx) => {
            if(username == asesor?.Usuario && password == asesor?.Clave && keyword.toLowerCase() === LLHAHKAFSDPHOPQÑLASDLJK.toLowerCase()) {
              matchIdx = idx;
            }
          })








          if(matchIdx !== null) {
            loadingSwal.close()
            setBrokerData({
              brokerID: asesoresDB[matchIdx].ID,
              brokerName: asesoresDB[matchIdx].Nombre,
            })
            setStepCounter(2)






          } else {
            //SUCCESSSS LOG NOT AUTHORIZED
            setStepCounter(0)
            loadingSwal.close()
              Swal.fire({
                title: 'No autorizado',
                icon: 'error',
                showConfirmButton: false,
                showCloseButton: false,
                iconColor: "#ff4545",
                timer: 2500
              }).then(() => {
                setStepCounter(0)
              })
          }
        })
        .catch(e => {
          setStepCounter(0)
          loadingSwal.close()
          Swal.fire({
            title: 'Ocurrió un error',
            icon: 'error',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#ff4545",
            timer: 2500
          }).then(() => {
            setStepCounter(0)
          })
        });
    }
  }, [username, password, keyword, stepCounter])
  
  return(
    <section className="step-wrap-container">
      <div className="step-container">
        <div style={{height: "55px"}} />
        <h1 className="h1">
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{textAlign: "center"}}>
              ¡Bienvenido afiliado <span style={{color: "#5ef867"}}>Clave</span>!
            </div>

            <img src={logo} style={{margin: "0 auto", height: "80px", margin: "20px 0"}} />
          </div>
        </h1>

        <h2 className="h2">
          <div >
            <h3 className="h3">Nombre de usuario</h3>
            <div style={{height: "10px"}} />
            
            <Input
              placeholder={""}
              value={username}
              onChange={e=>setUsername(e.target.value)}
            />

            <div style={{height: "30px"}} />

            <h3 className="h3">Contraseña</h3>
            <div style={{height: "10px"}} />
            
            <Input
              placeholder={""}
              value={password}
              onChange={e=>setPassword(e.target.value)}
            />

            <div style={{height: "30px"}} />

            <h3 className="h3">Palabra clave</h3>
            <div style={{height: "10px"}} />
            
            <Input
              placeholder={""}
              value={keyword}
              onChange={e=>setKeyword(e.target.value)}
            />
          </div>
        </h2>

        <div style={{height: "55px"}} />
      </div>
    </section>
  )
}