// react
import { useEffect, useState } from "react";

// components
import FormTemplate from "../../../Components/FormTemplate/FormTemplate";
import Input from "../../../Components/Input/Input";
import InputCalendar from "../../../Components/InputCalendar/InputCalendar";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import Textarea from "../../../Components/Textarea/Textarea";

import Swal from 'sweetalert2';
import SteinStore from "stein-js-client";


export default function({stepCounter, setStepCounter, setAbleNextStep, brokerData}) {

  // variables 
  const [brokerID, setBrokerID] = useState(brokerData.brokerID)
  const [brokerName, setBrokerName] = useState(brokerData.brokerName)
  const [date, setDate] = useState("")
  const [clientBirth, setClientBirth] = useState("")
  const [clientName, setClientName] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientAge, setClientAge] = useState("")
  const [product, setProduct] = useState(null)
  const [link1, setLink1] = useState(null)
  const [closingPorcentage, setClosingPorcentage] = useState(null)
  const [comments, setComments] = useState("")

  const store = new SteinStore(
    "https://api.steinhq.com/v1/storages/64b6e9f6d27cdd09f0069d47"
  );
  
  const productList = [
    {key: 0, name: "NINGUNO"},
    {key: 1, name: "VIDA"},
    // {key: 2, name: "DOTAL"},
    // {key: 3, name: "PPR"},
    {key: 4, name: "GMM"},
    {key: 5, name: "AUTO"},
    {key: 6, name: "HIPOTECARIO"},
    // {key: 7, name: "Inmobiliaria"},
    {key: 10, name: "CREDAVIT"},
    {key: 9, name: "PYME"},
  ]
  const porcentageList = [
    {key: 1, name: "25%"},
    {key: 2, name: "50%"},
    {key: 3, name: "75%"},
    {key: 4, name: "100%"},
  ]



  // functions
  function convertirFecha(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }

  function resetVariables() {
    setDate("") 
    setClientBirth("")
    setClientName("")
    setClientPhone("")
    setClientEmail("")
    setClientAge("")
    setProduct(null)
    setLink1(null)
    setClosingPorcentage(null)
    setComments("")
    setStepCounter(2)
  }
  


  // useEffects
  useEffect(() => {
    if(
      brokerID &&
      brokerName &&
      date &&
      clientName &&
      clientAge &&
      clientBirth &&
      product &&  
      closingPorcentage &&
      comments &&
      stepCounter !== 3 // and a form had already send (so wait)
      ) {
      setAbleNextStep(true)
    } else {
      setAbleNextStep(false)
    }
  }, [
    brokerID,
    brokerName,
    date,
    clientName,
    clientPhone,
    clientEmail,
    clientAge,
    clientBirth,
    product,
    link1,
    closingPorcentage,
    comments,
  ])

  useEffect(() => {
    if(stepCounter === 3) {
      const LeadData = {
        "Broker_ID":  brokerID,
        "Nombre_de_Broker": brokerName,
        "Fecha": convertirFecha(date),
        "Cliente": clientName,
        "Celular": clientPhone,
        "Email": clientEmail,
        "Edad": clientAge,
        "Fecha_de_nacimiento": convertirFecha(clientBirth),
        "Producto": product ? product.name : "",
        "Vincular_con_(1)": link1 ? link1.name : "NINGUNO",
        "Porcentaje_de_cierre": closingPorcentage ? closingPorcentage.name : "",
        "Comentarios": comments,
      }
      
      
      setAbleNextStep(false)
      
      //send data to excel
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
      
      
      store
        .append("Leads", [LeadData], {
          authentication: {
            username: "asesor", password: "clave2134e651871531098y8fg8792870431uif8uy8"
          }
        })
        .then(res => {
          loadingSwal.close()
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Enviar otro',
            buttonsStyling: false,
            showCloseButton: false,
            iconColor: "#0F70D4",
            // <a href="https://docs.google.com/spreadsheets/d/1pferj42DF2hqt7HiQaZMgTz7nkOO7UTcKEli1sy-w0Q/edit?usp=sharing" class="btn">Ver registros</a>
          }).then(() => {
            resetVariables()
          })
        })
        .catch(error => {
          console.error(error)
          setStepCounter(2)
        });
    } 
  }, [stepCounter])

  return(<FormTemplate>
    <h3 className="h3">Fecha</h3>
    <div style={{height: "10px"}} />
    <InputCalendar 
      value={date} 
      onChange={e=>setDate(e.value)}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Nombre del Cliente</h3>
    <div style={{height: "10px"}} />
    <Input
      placeholder={""}
      value={clientName}
      onChange={e=>setClientName(e.target.value)}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Celular</h3>
    <div style={{height: "10px"}} />
    <Input
      pattern="[+]?[0-9]*"
      placeholder={""}
      value={clientPhone}
      onChange={e=>setClientPhone((v) => (e.target.validity.valid ? e.target.value : v))}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Correo electrónico</h3>
    <div style={{height: "10px"}} />
    <Input
      placeholder={""}
      value={clientEmail}
      onChange={e=>setClientEmail(e.target.value)}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Edad del Cliente</h3>
    <div style={{height: "10px"}} />
    <Input
      pattern="[0-9]*"
      placeholder={""}
      value={clientAge}
      onChange={e=>setClientAge((v) => (e.target.validity.valid ? e.target.value : v))}
    />

    <div style={{height: "30px"}} />

    <h3 className="h3">Fecha de nacimiento</h3>
    <div style={{height: "10px"}} />
    <InputCalendar 
      value={clientBirth} 
      onChange={e=>setClientBirth(e.value)}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Producto</h3>
    <div style={{height: "10px"}} />
    <Dropdown 
      options={productList}
      optionLabel={"name"}
      value={product}
      onChange={e => setProduct(e.value)}
    />
    <div style={{height: "30px"}} />

    <h3 className="h3">Vincular con (1)</h3>
    <div style={{height: "10px"}} />
    <Dropdown 
      options={productList}
      optionLabel={"name"}
      value={link1}
      onChange={e => setLink1(e.value)}
    />
    
    <div style={{height: "30px"}} />

    <h3 className="h3">% cierre</h3>
    <div style={{height: "10px"}} />
    <Dropdown 
      options={porcentageList}
      optionLabel={"name"}
      value={closingPorcentage}
      onChange={e => setClosingPorcentage(e.value)}
    />

    
    <div style={{height: "30px"}} />

    <h3 className="h3">Comentarios</h3>
    <div style={{height: "10px"}} />
    <Textarea 
      value={comments}
      onChange={e=>setComments(e.target.value)}
    />


  </FormTemplate>)
}