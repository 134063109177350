import { useEffect, useState } from "react";

// src
import logo from "../src/img/logo.png"

// components
import Form from "./Pages/Steps/Form/Form";
import StepBtn from "./Components/StepBtn/StepBtn";
import Login from "./Pages/Steps/Login/Login";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';


// styles
import "./Styles/Globals.scss"
import "./Styles/Steps.scss"
import "./Styles/Layout.scss"

function App() {
  const [stepCounter, setStepCounter] = useState(0)
  const [ableNextStep, setAbleNextStep] = useState(true)
  const [brokerData, setBrokerData] = useState({
    brokerID: "",
    brokerName: ""
  })



  // props packs
  const StepsProps = {stepCounter, setStepCounter, ableNextStep, setAbleNextStep}
  const BrokerProps = {brokerData, setBrokerData}













  useEffect(() => {
    addLocale('es', {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Limpiar',
      //...
    });    
    locale('es');
  }, [])  
  










  return (<div className="layout">
    <div className="bg-overlay"/>
    
    <header className="Header"><img onClick={()=> window.location.href = "https://www.asesoresclave.com"} src={logo}/></header>
    
      <main className="App">
        <StepBtn disabled={true} {...StepsProps} direction="left" />








        {/* step 0 is the login */}
        {/* step 1 is the authentication api */}
        {stepCounter < 2 && <Login {...StepsProps} {...BrokerProps} />}

        {stepCounter >= 2 && <Form {...StepsProps} {...BrokerProps} />} 
        








        <StepBtn disabled={!ableNextStep} {...StepsProps} direction="right" />




        <div className="mobile-actions">
          <StepBtn disabled={true} {...StepsProps} direction="left" />
          <StepBtn disabled={!ableNextStep} {...StepsProps} direction="right" />
        </div>
      </main>
  </div>);
}

export default App;
